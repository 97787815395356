.el43px0 {
  --el43px1: 0;
  --el43px2: 4px;
  --el43px3: 8px;
  --el43px4: min(clamp(0.5rem, calc(0.2143125rem + 0.595vw), 0.75rem), clamp(0.5rem, calc(0.375rem + 0.500vh), 0.75rem));
  --el43px5: min(clamp(0.75rem, calc(0.4643125rem + 0.595vw), 1rem), clamp(0.75rem, calc(0.625rem + 0.500vh), 1rem));
  --el43px6: min(clamp(1rem, calc(0.4285625rem + 1.190vw), 1.5rem), clamp(1rem, calc(0.75rem + 1.000vh), 1.5rem));
  --el43px7: min(clamp(1.5rem, calc(0.9285625rem + 1.190vw), 2rem), clamp(1.5rem, calc(1.25rem + 1.000vh), 2rem));
  --el43px8: min(clamp(2.625rem, calc(1.625rem + 2.083vw), 3.5rem), clamp(2.625rem, calc(2.1875rem + 1.750vh), 3.5rem));
}
.el43px9 {
  --el43pxa: min(clamp(1rem, calc(0.4285625rem + 1.190vw), 1.5rem), clamp(1rem, calc(0.75rem + 1.000vh), 1.5rem));
  --el43pxb: #01838C;
  --el43pxc: #00B0B9;
  --el43pxd: #004651;
  --el43pxe: #E6EDEE;
  --el43pxf: #CCE6E8;
  --el43pxg: #99CDD1;
  --el43pxh: #67B5BA;
  --el43pxi: #E8E6E2;
  --el43pxj: #F0EEEA;
  --el43pxk: #BDBDBD;
  --el43pxl: #FFF;
  --el43pxm: #000;
  --el43pxn: #E40571;
  --el43pxo: min(clamp(12.5rem, calc(-1.7856875rem + 29.762vw), 25rem), clamp(12.5rem, calc(6.25rem + 25.000vh), 25rem));
  --el43pxp: min(clamp(4.6875rem, calc(-0.8125rem + 11.458vw), 9.5rem), clamp(4.6875rem, calc(2.28125rem + 9.625vh), 9.5rem));
  --el43pxq: min(clamp(3rem, calc(-0.4285625rem + 7.143vw), 6rem), clamp(3rem, calc(1.5rem + 6.000vh), 6rem));
  --el43pxr: min(clamp(2.5rem, calc(-0.357125rem + 5.952vw), 5rem), clamp(2.5rem, calc(1.25rem + 5.000vh), 5rem));
  --el43pxs: min(clamp(2rem, calc(-0.2856875rem + 4.762vw), 4rem), clamp(2rem, calc(1rem + 4.000vh), 4rem));
  --el43pxt: min(clamp(1.25rem, calc(-0.1785625rem + 2.976vw), 2.5rem), clamp(1.25rem, calc(0.625rem + 2.500vh), 2.5rem));
  --el43pxu: min(clamp(1rem, calc(0.4285625rem + 1.190vw), 1.5rem), clamp(1rem, calc(0.75rem + 1.000vh), 1.5rem));
  --el43pxv: min(clamp(1.125rem, calc(0.4106875rem + 1.488vw), 1.75rem), clamp(1.125rem, calc(0.8125rem + 1.250vh), 1.75rem));
  --el43pxw: min(clamp(1.25rem, calc(-0.1785625rem + 2.976vw), 2.5rem), clamp(1.25rem, calc(0.625rem + 2.500vh), 2.5rem));
  --el43pxx: min(clamp(1rem, calc(0.4285625rem + 1.190vw), 1.5rem), clamp(1rem, calc(0.75rem + 1.000vh), 1.5rem));
  --el43pxy: min(clamp(0.75rem, calc(0.4643125rem + 0.595vw), 1rem), clamp(0.75rem, calc(0.625rem + 0.500vh), 1rem));
  --el43pxz: min(clamp(1rem, calc(0.4285625rem + 1.190vw), 1.5rem), clamp(1rem, calc(0.75rem + 1.000vh), 1.5rem));
  --el43px10: min(clamp(0.875rem, calc(0.4464375rem + 0.893vw), 1.25rem), clamp(0.875rem, calc(0.6875rem + 0.750vh), 1.25rem));
  --el43px11: 1200;
  --el43px12: var(--el43px8);
  --el43px13: var(--el43px8);
  --el43px14: var(--el43px8);
  --el43px15: 0.75;
  --el43px16: 0.5625;
  --el43px17: 100vw;
  --el43px18: 100vh;
  --el43px19: 75px;
  --el43px1a: 100px;
  --el43px1b: 60px;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_Slab_99b6d2';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/e8e25437b49f95eb-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_Slab_99b6d2';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/1503d3bdd3f79a19-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_Slab_99b6d2';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/e2a272b6447367f1-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_Slab_99b6d2';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/be657e7655c1bafa-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_Slab_99b6d2';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/468b79e2d56358f9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_Slab_99b6d2';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/b0da285322ee6896-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_Slab_99b6d2';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/d3d85d86ad5c431a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_Slab_99b6d2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e8e25437b49f95eb-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_Slab_99b6d2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1503d3bdd3f79a19-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_Slab_99b6d2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e2a272b6447367f1-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_Slab_99b6d2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/be657e7655c1bafa-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_Slab_99b6d2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/468b79e2d56358f9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_Slab_99b6d2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b0da285322ee6896-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_Slab_99b6d2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d3d85d86ad5c431a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_Slab_99b6d2';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e8e25437b49f95eb-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_Slab_99b6d2';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/1503d3bdd3f79a19-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_Slab_99b6d2';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e2a272b6447367f1-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_Slab_99b6d2';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/be657e7655c1bafa-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_Slab_99b6d2';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/468b79e2d56358f9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_Slab_99b6d2';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/b0da285322ee6896-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_Slab_99b6d2';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d3d85d86ad5c431a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Slab_Fallback_99b6d2';src: local("Times New Roman");ascent-override: 89.69%;descent-override: 23.20%;line-gap-override: 0.00%;size-adjust: 116.83%
}.__className_99b6d2 {font-family: '__Roboto_Slab_99b6d2', '__Roboto_Slab_Fallback_99b6d2';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_3fa15b';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/88da59c891d37117-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_3fa15b';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1e377d93cbdd6b44-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_3fa15b';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/36d711d427a06e51-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_3fa15b';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f1f7cdf240180e28-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_3fa15b';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/55fbdf033607a0e1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_3fa15b';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b6a6f0b43d027304-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_3fa15b';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/9cf9c6e84ed13b5e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_3fa15b';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/88da59c891d37117-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_3fa15b';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/1e377d93cbdd6b44-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_3fa15b';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/36d711d427a06e51-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_3fa15b';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/f1f7cdf240180e28-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_3fa15b';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/55fbdf033607a0e1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_3fa15b';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/b6a6f0b43d027304-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_3fa15b';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/9cf9c6e84ed13b5e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Source_Sans_3_Fallback_3fa15b';src: local("Arial");ascent-override: 109.21%;descent-override: 42.66%;line-gap-override: 0.00%;size-adjust: 93.76%
}.__className_3fa15b {font-family: '__Source_Sans_3_3fa15b', '__Source_Sans_3_Fallback_3fa15b';font-style: normal
}

